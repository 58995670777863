import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <h1>Kontaktieren</h1>
      <div className="card-container">
        <form className="contact-form">
          <label for="name">Name:</label>
          <input type="text" id="name" name="name"></input>
          <br />
          <label for="email">Email:</label>
          <input type="email" id="email" name="email"></input>
          <br />
          <label for="message">Nachricht:</label>
          <textarea id="message" name="message"></textarea>
          <br />
          <input type="submit" value="Submit"></input>
        </form>
      </div>
    </div>
  );
};

export default Contact;
