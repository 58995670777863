import React, { useState, useEffect } from 'react';
import './navbar.css';
import { BsSuitHeart, BsHandbag } from 'react-icons/bs';
import { MdOutlineContactSupport } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/lucidme-logo-black.png';


const Navbar = ({ onCartClick, setSelectedFilter }) => {
  const [showNav, setShowNav] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPos]);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = currentScrollPos < 20 || scrollPos > currentScrollPos;
  
    setScrollPos(currentScrollPos);
    setShowNav(visible);
  };

  return (
    <nav className={showNav ? 'navbar' : 'navbar hidden'}>
      <div className='navbar-container'>
        <Link className='link logo-link' to='/'>
          <div className='navbar-logo'>
            <img className='logo-img' src={Logo} alt="logo" />
            <h1>Lucid<span>Me</span></h1>
          </div>
        </Link>
        <div className='navbar-actions'>
          <button className='action-btn cart-btn ' onClick={onCartClick}><BsHandbag /></button>
          <button onClick={() => setSelectedFilter('favorites')} className='action-btn favorites-btn'><BsSuitHeart /></button>
          <Link to={`/contact`}>
            <button className='action-btn contact-btn'><MdOutlineContactSupport/></button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
