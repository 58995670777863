import React from 'react';
import './header.css';

import bkgimg from '../../assets/images/crisp-background.jpg'


const Header = () => {
  return (
    <header className='header'>
      
      <img src={bkgimg} className='header__background' alt="header background" />
      <div className='header__container'>
        <h3 className='header__message'>LucidMe - psychedelisch inspirierte Mode</h3>
      </div>
    </header>
  );
};

export default Header;