import React, { useContext } from "react";
import CartContext from "../../../context/CartContext";
import axios from "axios";
import "./checkout.css";

const Checkout = () => {
  const {
    cartItems,
    updateSize,
    removeFromCart,
    incrementItem,
    decrementItem,
  } = useContext(CartContext);
  
  const totalPrice = cartItems.reduce(
    (total, item) => total + (item.price / 100) * item.quantity,
    0
  );

  const handleCheckout = () => {
    axios
      .post("https://lucidme-cda14f11469f.herokuapp.com/create-checkout-session", {
        cartItems: cartItems.map(item => ({ 
          id: item._id,
          quantity: item.quantity,
          size: item.size, 
        })),
      })
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div className="checkout">
      <div className="checkout-items">
        {cartItems.map((item) => (
          <div key={item.id} className="checkout-item">
            <img src={item.images[0]} alt={item.name} className="checkout-item-image" />
            <div className="checkout-item-detail">
              <p className="checkout-item-name">{item.name}</p>
              <p className="checkout-item-price">€{((item.price / 100) * item.quantity).toFixed(2)}</p>
            </div>
            <div className="checkout-item-controls">
              <button className="checkout-quantity-btn" onClick={() => incrementItem(item._id)}>+</button>
              <span>{item.quantity}</span>
              <button className="checkout-quantity-btn" disabled={item.quantity === 1} onClick={() => decrementItem(item._id)}>-</button>
              <button className="checkout-item-remove-btn" onClick={() => removeFromCart(item._id)}>Entfernen</button>
            </div>
          </div>
        ))}
      </div>
      <div className="checkout-summary">
        <h3>Total: €{totalPrice.toFixed(2)}</h3>
        <button className="checkout-btn" onClick={handleCheckout}>Bezahlen</button>
      </div>
    </div>
  );
};

export default Checkout;
