import React, { useState, useEffect } from 'react';
import FavoritesContext from './FavoritesContext';

const FavoritesProvider = ({ children }) => {
  const [favoriteItems, setFavoriteItems] = useState(() => {
    const savedFavoriteItems = localStorage.getItem('favoriteItems');
    return savedFavoriteItems ? JSON.parse(savedFavoriteItems) : [];
  });

  useEffect(() => {
    localStorage.setItem('favoriteItems', JSON.stringify(favoriteItems));
  }, [favoriteItems]);

  const addToFavorites = (item) => {
    setFavoriteItems(prevItems => [...prevItems, item]);
  };

  const removeFromFavorites = (itemId) => {
    setFavoriteItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };

  const isItemInFavorites = (itemId) => {
    return favoriteItems.some(item => item._id === itemId);
  };

  return (
    <FavoritesContext.Provider value={{ favoriteItems, addToFavorites, removeFromFavorites, isItemInFavorites  }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesProvider;
