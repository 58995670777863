import React, { useState, useEffect } from 'react';
import CartContext from './CartContext';

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item, size = 'M') => {
    setCartItems(prevItems => [...prevItems, {...item, size, quantity: item.quantity ? item.quantity : 1}]);
  };
  
const updateSize = (itemId, newSize) => {
  setCartItems(prevItems => prevItems.map(item => item._id === itemId ? {...item, size: newSize} : item));
};

  const removeFromCart = (itemId) => {
    setCartItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };

 
  const isItemInCart = (itemId) => {
    return cartItems.some(item => item._id === itemId);
  };

  const incrementItem = (itemId) => {
    setCartItems((prevItems) => 
      prevItems.map(item => 
        item._id === itemId ? {...item, quantity: item.quantity + 1} : item
      )
    );
  };

  const decrementItem = (itemId) => {
    setCartItems((prevItems) => 
      prevItems.map(item => 
        item._id === itemId ? {...item, quantity: item.quantity > 0 ? item.quantity - 1 : 0} : item
      )
    );
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, incrementItem, decrementItem,  isItemInCart,updateSize  }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
