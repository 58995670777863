import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import CartContext from '../../context/CartContext';
import './cart.css';

const Cart = ({ onClose }) => {
  const { cartItems,updateSize, removeFromCart } = useContext(CartContext);
  const [isClosing, setIsClosing] = useState(false);
  const total = cartItems.reduce((sum, item) => sum + (item.price/100 * item.quantity), 0);
  const cartRef = useRef();

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 200); // same duration as your animation
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  return (
    <div className={`cart ${isClosing ? 'animate-out' : 'animate-in'}`} ref={cartRef}>
      <div className='cart-header'>
        <h2>Einkaufswagen</h2>
        <button className='cart-close-btn' onClick={handleClose}><AiOutlineClose /></button>
      </div>
      {cartItems.length === 0 ?
      <p className='cart-empty-info'>Ihr Einkaufswagen ist leer</p> :
      <>
       {cartItems.map(item => (
        <div key={item.id} className='cart-item'>
          <div className='cart-item-info'>
            <img src={item.images[0]} alt={item.name} className='cart-item-image' />
            <div>
              <p className='cart-item-name'>{item.name}</p>
              <p className='cart-item-price'>€{(item.price/100  * item.quantity).toFixed(2)}</p>
            </div>
          </div>
          <div className='cart-item-controls'>
            <button className='cart-item-remove-btn' onClick={() => removeFromCart(item._id)}><RiDeleteBinLine size={24} /></button> {/* <-- Changed this line */}
          </div>
        </div>
      ))}
      <div className='cart-total'>Gesamtsumme: €{total.toFixed(2)}</div>
      
      <Link to="/checkout" className="cart__checkout link"  onClick={onClose}>Zur Kasse</Link>
   
      
      
      </>
      }
      
    </div>
  );
};

export default Cart;
