import React from 'react'
import Header from '../../header/Header'
import ProductsList from '../../products-list/ProductsList'



const Home = ({productsData, selectedFilter, setSelectedFilter}) => {
  return (
    <div>
        <Header/>
        <ProductsList selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
    </div>
  )
}

export default Home