import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "./product-view.css";
import { Link } from "react-router-dom";
import CartContext from "../../../context/CartContext";

function ProductView() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const { addToCart, isItemInCart, updateSize } = useContext(CartContext);
  const [size, setSize] = useState("M");
  const [itemAdded, setItemAdded] = useState(() => isItemInCart(productId));

  useEffect(() => {
    fetch(`https://lucidme-cda14f11469f.herokuapp.com/products/${productId}`)
      .then((response) => response.json())
      .then((data) => setProduct(data))
      .catch((error) => console.error(error));
  // If product is in cart, set itemAdded to true
  if(isItemInCart(productId)){
    setItemAdded(true);
  }
}, [productId, isItemInCart]);

  if (!product) {
    return (
      <div className="product-view">
    <div>Loading product...</div>
    </div>);
  }

  const inCart = isItemInCart(product._id);

  const handleSizeChange = (size) => {
    setSize(size);
    updateSize(product._id, size);
  };

  return (
    <div className="product-view">
      <div className="product-view__image-container">
        <Carousel>
          {product.images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`${product.name} ${index}`}
                className="product-view__image"
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="product-view__info">
        <h1 className="product-view__name">{product.name}</h1>
        <p className="product-view__price">€{product.price / 100}</p>
        <div className="product-view__size-selector">
          {["S", "M", "L", "XL"].map((sizeOption) => (
            <button
              key={sizeOption}
              className={`product-view__size-option ${
                size === sizeOption ? "selected" : ""
              }`}
              onClick={() => handleSizeChange(sizeOption)}>
              {sizeOption}
            </button>
          ))}
        </div>
        
        {!itemAdded && (
        <button
          onClick={() => {
            addToCart(product, size);
            setItemAdded(true);
          }}
          className="product-view__add-to-cart"
        >
          In den Einkaufswagen
        </button>
      )}
      {itemAdded && (
        <Link to="/checkout" className="product-view__checkout link">
          Zur Kasse
        </Link>
      )}
      </div>
    </div>
  );
}

export default ProductView;
