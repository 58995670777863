import React, { useState } from "react";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import ProductsList from "./components/products-list/ProductsList";
import CartProvider from "./context/CartProvider";
import FavoritesProvider from "./context/FavoritesProvider";
import Cart from "./components/cart/Cart";
import { BrowserRouter as Router, Route, Routes, link } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Checkout from "./components/pages/checkout/Checkout";
import ProductView from "./components/pages/product-view/ProductView";
import Footer from "./components/footer/Footer";
import Contact from "./components/pages/contact/Contact";
import CheckoutSuccess from "./components/pages/checkout/CheckoutSuccess";

function App() {
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("neu")
  const toggleCartVisibility = () => {
    setIsCartVisible(!isCartVisible);
  };

  return (
    <Router>
    <CartProvider>
    <FavoritesProvider>
     
      <div className="App">
        <Navbar onCartClick={toggleCartVisibility} setSelectedFilter={setSelectedFilter} />
        
        {isCartVisible && <Cart onClose={toggleCartVisibility} />}
        
          <Routes>
            <Route
              exact
              path="/"
              element={<Home setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter}  />}
            />

            <Route exact path="/checkout" element={<Checkout/>} />
            <Route exact path="/product/:productId" element={<ProductView/>} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/checkout-success" element={<CheckoutSuccess/>} />

          </Routes>
        
        <Footer/>
      </div>
    </FavoritesProvider>
    </CartProvider>
    </Router>
  );
}

export default App;
