import React from 'react';
import './footer.css';
import { FaApplePay, FaCcVisa, FaCcMastercard, FaGooglePay } from 'react-icons/fa';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section about">
        <h1 className="logo-text">LucidMe</h1>
        <p>
          LucidMe ist eine Website, die hochwertige, psychedelisch inspirierte Mode anbietet...
        </p>
        <div className="payments">
          <FaCcVisa className="payment-icon"/>
          <FaCcMastercard className="payment-icon"/>
          
          <FaApplePay className="payment-icon"/>
          <FaGooglePay className="payment-icon"/>
        </div>
      </div>

     

      <div className="footer-bottom">
        &copy; 2023 lucidme.de
      </div>
    </footer>
  )
}

export default Footer;
