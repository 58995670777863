import React from 'react';
import './checkout-success.css'

const CheckoutSuccess = () => {
  return (
    <div className="checkout-success">
      <h1>Danke für Ihren Einkauf!</h1>
      <p>Ihre Bestellung wurde erfolgreich aufgegeben. Sie werden in Kürze eine Bestätigung per E-mail erhalten.</p>
      <a href="/">Weiter Einkaufen</a>
    </div>
  );
};

export default CheckoutSuccess;
