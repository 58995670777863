import React, { useState, useEffect, useContext } from 'react';
import './products-list.css';
import Product from '../product/Product';
import FavoritesContext from '../../context/FavoritesContext';

const ProductsList = ({selectedFilter, setSelectedFilter}) => {
  const [selectedCategory, setSelectedCategory] = useState('alle');
  
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const { favoriteItems } = useContext(FavoritesContext);
  const { addToFavorites, isItemInFavorites } = useContext(FavoritesContext);
  
  const categories = ['alle', 't-shirt', 'hoodie', 'pullover'];

  useEffect(() => {
    fetch('https://lucidme-963eac810aa5.herokuapp.com/products')
      .then(response => response.json())
      .then(data => setProductsData(data));
  }, []);

  useEffect(() => {
    const filteredProducts = selectedFilter === 'favorites'
      ? favoriteItems
      : selectedCategory === 'alle'
        ? productsData // Display all products if "All" category is selected
        : productsData.filter(product => product.category === selectedCategory);

    setDisplayedProducts(filteredProducts);
  }, [productsData, selectedCategory, selectedFilter, favoriteItems]);

  const handleFilterClick = filter => {
    setSelectedFilter(filter);
  };

  const handleCategoryClick = category => {
    setSelectedCategory(category);
  };

  return (
    <div className='products'>
      <div className='products__filters--categories'>
        <div className='products__filters'>
          <button 
            className={`products__filter filterneu ${selectedFilter === 'neu' ? 'selected' : ''}`} 
            onClick={() => handleFilterClick('neu')}
          >
            Neu
          </button>
          <span>|</span>
          <button 
            className={`products__filter filterfavorites ${selectedFilter === 'favorites' ? 'selected' : ''}`} 
            onClick={() => handleFilterClick('favorites')}
          >
            Favoriten
          </button>
        </div>

        <div className="product__categories">
          {categories.map(category => (
            <button
              className={`products__filter ${selectedCategory === category ? 'selected' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}

          
          <div className="dropdown">
            <select 
              value={selectedCategory} 
              onChange={e => handleCategoryClick(e.target.value)}
            >
              {categories.map(category => (
                <option value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </option>
              ))}
            </select>
          </div>
        </div>

      </div>

      <div className='products__list'>
        {selectedFilter === 'favorites' && favoriteItems.length === 0 ? (
          <div className='products__list-empty-favorites-message'>Du hast keine Produkten in deine Favoriten Liste</div>
        ) : (
          displayedProducts.map(product => (
            <Product key={product._id} product={product} selectedFilter={selectedFilter} />
          ))
        )}
      </div>
    </div>
  );
};

export default ProductsList;
