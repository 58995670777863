import React, { useContext } from 'react';
import './product.css';
import { Link } from 'react-router-dom';
import { FaRegHeart, FaHeart } from 'react-icons/fa';  // Import these two
import FavoritesContext from '../../context/FavoritesContext';

const Product = ({ product, selectedFilter }) => {
  const { favoriteItems, addToFavorites, removeFromFavorites, isItemInFavorites } = useContext(FavoritesContext);
  const isFavorite = isItemInFavorites(product._id);

  const toggleFavorite = (e) => {
    e.preventDefault();
    if (isFavorite) {
      removeFromFavorites(product._id);
    } else {
      addToFavorites(product);
    }
  };

  return (
    
      <div className='product' >
        {product.onSale && <div className='product__sale'>Sale</div>}
        <Link to={`/product/${product._id}`}>
          <div className='product__image-wrapper'>
            <img src={product.images[0]} alt={product.name} className='product__image' />
            <button
              onClick={toggleFavorite}
              className="product__favorite-icon"
            >
              {isFavorite ? <FaHeart /> : <FaRegHeart />}
            </button>
          </div>
        </Link>
        <div className='product__info'>
          <h3 className='product__name'>{product.name}</h3>
          <p className='product__price'>€{product.price/100}</p>
        </div>
      </div>
    );
  }


export default Product;
